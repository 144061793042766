import { filter } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import "rxjs/add/operator/map"
import { globalValues } from "@app/globals"
import { CustomHttp } from "@service/customhttp.service"
import { RequestLibraryService } from "@service/core/request-library.service"
import { AppConfig } from "@service/app.config"
import { HttpClient } from "@angular/common/http"

@Injectable()
export class ContactService {
  private customHttp: CustomHttp
  constructor(
    private http: HttpClient,
    private router: Router,
    private requestLibrary: RequestLibraryService
  ) {
    this.customHttp = new CustomHttp(http, router)
  }

  refundCallPrice(model) {
    return this.requestLibrary.Post("/contacts/refundrequest", model)
  }

  getLead(id) {
    return this.requestLibrary.GetAll("/contacts/" + id, {
      options: {
        expand: [
          "expand_campaign_history",
          "calls",
          "tests",
          "visitor",
          "status_history",
          "status",
          "form_history",
          "field",
          "tasks",
        ],
      },
    })
  }

  pause(id, status) {
    return this.requestLibrary.Post("/contacts/" + id + "/pause", {
      status: status,
    })
  }
  addNote(model) {
    return this.requestLibrary.Post("/contacts", model)
  }

  getPaginatedLeads(page, page_size, sort, sort_dir, criteriaArray) {
    return this.requestLibrary.GetAll("/contacts", {
      options: {
        page: page,
        page_size: page_size,
        sort: sort,
        sort_dir: sort_dir,
        expand: ["status"],
        criteria: criteriaArray,
      },
    })
  }

  getExportFilteredLeads(criteriaArray) {
    return this.requestLibrary.GetCSV("/contacts", {
      options: {
        is_export: true,
        page_size: AppConfig.exportLimit,
        sort: "id",
        sort_dir: "DESC",
        criteria: criteriaArray,
      },
    })
  }

  getFilteredContacts(page, page_size, criteriaArray) {
    return this.requestLibrary.GetAll("/contacts", {
      options: {
        page: page,
        page_size: page_size,
        criteria: criteriaArray,
      },
    })
  }

  getLeads() {
    return this.requestLibrary.GetAll("/contacts")
  }

  getCompactLeads() {
    return this.requestLibrary.GetAll("/contacts", {
      options: { page_size: "all", is_compact: true },
    })
  }

  deleteLead(id: string) {
    return this.requestLibrary.Delete("/contacts/" + id)
  }

  checkImportQueueStatus() {
    return this.requestLibrary.Get("/contacts/importqueue/status")
  }

  getImportQueue(
    org_id: number,
    page: number,
    page_size: number,
    sort: string,
    sort_dir: string,
    filters: any
  ) {
    return this.requestLibrary.GetAll("/contacts/importqueue", {
      options: {
        org_id: org_id,
        page: page,
        page_size: page_size,
        sort: sort,
        sort_dir: sort_dir,
        filterCriteria: filters
      },
    })
  }

  private extractData(res: Response) {
    let body = res.json()
    return body
  }

  getCampaigns(id) {
    return this.requestLibrary.GetAll("/contacts/" + id, {
      options: { expand: ["expand_campaign_history"] },
    })
  }

  getTimeline(id) {
    return this.requestLibrary.Get("/contacts/" + id + "/history")
  }

  getContactNoteTimeline(id) {
    return this.requestLibrary.GetAll("/contacts/" + id + "/notes", {
      options: { expand: ["notes"] },
    })
  }
  getContactEmailTimeline(id) {
    return this.requestLibrary.GetAll("/contacts/" + id + "/email", {
      options: { expand: ["email"] },
    })
  }

  saveForm(model) {
    return this.requestLibrary.Post("/contacts", model)
    // return this.customHttp.post('/contacts', model);
  }

  getAllContactWebHooks() {
    return this.customHttp.get("/contacts/inboundwebhook")
  }

  getAllUsers() {
    return this.customHttp.get("/contacts/exportusers")
  }
  getImportTemplate() {
    return this.customHttp.getDownloadableCsv(
      "/contactsimports/template?options=",
      {}
    )
  }

  getTotalContactConversionRateCount(since) {
    return this.customHttp.getAll(
      "/contacts/reports/total-conversion-rate?options=",
      { date_interval: since }
    )
  }
  /**
   * Addiction Treatment Services
   */

  runVob(contact_id, model) {
    return this.requestLibrary.Post("/contacts/" + contact_id + "/event", model)
  }
  // getTotalVOB(event_id) {
  //   return this.requestLibrary.GetAll('/events/reports/count-per-id', {
  //     options: { event_id: event_id },
  //     event_id: event_id
  //   });
  // }

  referOut(model) {
    return this.requestLibrary.Post("/contacts", model)
  }
  deleteHistoryNote(id: string) {
    return this.requestLibrary.Delete("/notes/" + id)
  }

  deleteAutomationHistory(conatct_id: string, history_id: string) {
    return this.requestLibrary.Delete(
      `/contacts/${conatct_id}/automation/${history_id}`
    )
  }
  updateHistoryNote(payload) {
    return this.requestLibrary.Post("/notes", payload)
  }
  getPaginatedContactWebHooks(body) {
    return this.requestLibrary.GetAll("/contacts/inboundwebhook", {
      options: body,
    })
  }
  getExportedContactWebHooks(args) {
    return this.requestLibrary.GetCSV("/contacts/inboundwebhook", {
      options: args,
    })
  }
}
