import { APP_INITIALIZER, ErrorHandler } from "@angular/core"
import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { NgxPermissionsService } from "ngx-permissions"
// import { AuthConfig, AuthHttp } from 'angular2-jwt/angular2-jwt';
import { Title } from "@angular/platform-browser"
import {
  AbTestReportService,
  AbTestService,
  AccountBillDetailService,
  AccountInvoiceService,
  AccountReportService,
  AccountService,
  AppService,
  AuthService,
  AutomationReportService,
  AutomationActionService,
  AutomationService,
  CallFilterService,
  CallReportService,
  CallService,
  CampaignReportService,
  CampaignService,
  ContactActionService,
  ContactFilterService,
  ContactPurchaseService,
  ContactRefundModerateService,
  ContactReportService,
  ContactService,
  ContactExportService,
  CustomHttp,
  DashboardService,
  IntegrationService,
  EmailService,
  EmailTemplateReportService,
  EmailTemplateService,
  EventService,
  FieldService,
  FormReportService,
  FormService,
  GroupService,
  HelpersService,
  LeadPriceService,
  LocalFilterStorageService,
  PhoneNumberService,
  PickListService,
  PrivateLabelService,
  PrivilegeService,
  RefundReasonService,
  RequestLibraryService,
  RoleService,
  CallScheduleService,
  SenderPhoneNumberService,
  SettingsService,
  SetupAppService,
  SetupRefundReasonService,
  // services
  SmsTemplateService,
  StatusReportService,
  StatusService,
  ToasterLibraryService,
  UserContextService,
  UserReportService,
  UserService,
  VisitorService,
  WebhookService,
  PlanService,
  UserPrivilegeContextService,
  RequestCacheService,
  ConsoleLoggerService,
  LoggerService,
  StripeService,
  AddictionTreatmentSuiteService,
  FilterService,
  ContactDropdownStorageService,
  ScriptLoaderService,
  PageTitleService,
  OnBoardReportService,
  CustomerOnboardService,
  CustomerOnboardHelperService,
  CustomerOnboardRedirectService,
} from "@service/index"

import {
  HttpHeadersInterceptor,
  TimeoutInterceptor,
  TimingInterceptor,
  CacheInterceptor,
} from "@intercept/index"
import { SentryErrorHandler } from "@service/sentry/sentry-error-handler.service"
import { SentryService } from "@service/sentry/sentry-init.service"
// export function authHttpServiceFactory(http: Http, options: RequestOptions) {
//     return new AuthHttp(new AuthConfig({
//         tokenName: 'id_token',
//         tokenGetter: (() => localStorage.getItem('id_token')),
//         globalHeaders: [{ 'Content-Type': 'application/json' }],
//     }), http, options);
// }

export function loadPermissionsFactory(
  authservice: AuthService,
  ps: NgxPermissionsService
) {
  return () => {
    return Promise.resolve().then(data => {
      return (
        authservice.isAuthenticated() &&
        ps.loadPermissions(
          JSON.parse(localStorage.getItem("userPrivileges")) || []
        )
      )
    })
  }
}

export function initializeSentryFactory(sentryService: SentryService) {
  return () => sentryService.initializeSentry()
}

/*
 * Application Providers/Services
 * providers/directives/pipes that only live in our browser environment
 */
export const APPLICATION_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: (
      authService: AuthService,
      ngxPermissionsService: NgxPermissionsService
    ) => {
      return () => loadPermissionsFactory(authService, ngxPermissionsService)()
    },
    deps: [AuthService, NgxPermissionsService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (sentryService: SentryService) => {
      return () => initializeSentryFactory(sentryService)()
    },
    deps: [SentryService],
    multi: true,
  },
  // {
  //     provide: AuthHttp,
  //     useFactory: authHttpServiceFactory,
  //     deps: [Http, RequestOptions]
  // },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeadersInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TimingInterceptor,
    multi: true,
  },
  // {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: TimeoutInterceptor,
  //     multi: true,
  // },
  // {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: CacheInterceptor,
  //     multi: true,
  // },
  {
    provide: ErrorHandler,
    useClass: SentryErrorHandler,
  },
  { provide: LoggerService, useClass: ConsoleLoggerService },
  RequestCacheService,
  ToasterLibraryService,
  RequestLibraryService,
  AppService,
  HelpersService,
  LocalFilterStorageService,
  PickListService,
  CustomHttp,
  EmailTemplateService,
  AuthService,
  SettingsService,
  DashboardService,
  Title,

  // services
  SmsTemplateService,
  SenderPhoneNumberService,
  IntegrationService,
  EmailService,
  PrivateLabelService,
  LeadPriceService,
  WebhookService,
  PhoneNumberService,
  StatusService,
  StatusReportService,
  CampaignService,
  CampaignReportService,
  EventService,
  RefundReasonService,
  CallScheduleService,
  AccountService,
  AccountInvoiceService,
  AccountReportService,
  AccountBillDetailService,
  UserService,
  UserPrivilegeContextService,
  UserReportService,
  GroupService,
  PlanService,
  ContactService,
  ContactExportService,
  ContactActionService,
  ContactFilterService,
  ContactRefundModerateService,
  ContactPurchaseService,
  ContactReportService,
  AutomationService,
  AutomationReportService,
  AutomationActionService,
  CallService,
  CallFilterService,
  CallReportService,
  FormService,
  FormReportService,
  FieldService,
  SetupAppService,
  AbTestService,
  AbTestReportService,
  EmailTemplateReportService,
  RoleService,
  VisitorService,
  PrivilegeService,
  UserContextService,
  SetupRefundReasonService,
  StripeService,
  AddictionTreatmentSuiteService,
  FilterService,
  ContactDropdownStorageService,
  ScriptLoaderService,
  PageTitleService,
  OnBoardReportService,
  CustomerOnboardService,
  CustomerOnboardHelperService,
  CustomerOnboardRedirectService,
]