import { Pipe, PipeTransform } from "@angular/core"
import * as moment from "moment-timezone"

@Pipe({
  name: "changeTimezone",
})
export class ChangeTimezonePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === null || value === undefined) {
      console.error(null, `Invalid Date ${value} ${args}`);
      return 'Invalid Date'
    }

    const timezone = localStorage.getItem('account_timezone') || 'UTC';
    const format = args || 'MM dd, YYYY';

    if (!moment.tz.zone(timezone) || !moment(value).isValid()) {
      console.error('Invalid timezone/date:', `${timezone} and ${value}`);
      return 'Invalid Timezone/Date'
    }

    const timestamp = moment(value).utc();

    return moment(timestamp).tz(timezone).format(format)
  }
}
