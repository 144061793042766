import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "currency" })
export class FormatPricePipe implements PipeTransform {
  transform(
    amount: number | string,
    currency: string = "USD",
    precision: number | string = 2
  ): string {
    if (!amount && amount !== 0) return ""

    try {
      if (typeof amount === "string" && amount.includes("[")) {
        const formattedAmount = amount.replace(
          /\[(\w+):\s*(.*?)\]/g,
          (match, currencyCode, rawAmount) => {
            const numAmount = parseFloat(rawAmount.trim())
            if (!isNaN(numAmount)) {
              return this.formatWithLocale(numAmount, currencyCode, precision)
            }
            return match
          }
        )
        return formattedAmount
      }

      const numAmount = parseFloat(amount.toString().replace(/,/g, "."))
      if (isNaN(numAmount)) return ""
      return this.formatWithLocale(numAmount, currency, precision)
    } catch (e) {
      console.error("FormatPricePipe error:", e)
      return ""
    }
  }

  private formatWithLocale(
    amount: number,
    currency: string,
    precision: number | string
  ) {
    switch (currency?.toUpperCase()) {
      case "JPY":
        return `${new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: "JPY",
          minimumFractionDigits: Number(precision),
          maximumFractionDigits: Number(precision),
        }).format(amount)}`
      case "EUR":
        return `${new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: Number(precision),
          maximumFractionDigits: Number(precision),
        }).format(amount)}`
      case "GBP":
        return `${new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP",
          minimumFractionDigits: Number(precision),
          maximumFractionDigits: Number(precision),
        }).format(amount)}`
      case "USD":
        return `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: Number(precision),
          maximumFractionDigits: Number(precision),
        }).format(amount)}`
      case "AUD":
        return `${new Intl.NumberFormat("en-AU", {
          style: "currency",
          currency: "AUD",
          minimumFractionDigits: Number(precision),
          maximumFractionDigits: Number(precision),
        }).format(amount)}`
      default:
        return `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: Number(precision),
          maximumFractionDigits: Number(precision),
        }).format(amount)}`
    }
  }
}
