<fieldset>
  <div class="form-group" [formGroup]="form">
    <label class="col-sm-4 control-label" [attr.for]="question.key">{{question.label}} </label>
    <div class="col-sm-8" [ngSwitch]="question.controlType">
      <!-- <textarea class="form-control text-area-width" style="min-width: 100%;min-height: 100px" *ngSwitchCase="'html'" [formControlName]="question.key"
        [id]="question.key" [type]="question.type" ></textarea> -->

      <html *ngSwitchCase="'html'">
      <div [innerHTML]="question.value"></div>

      </html>
      <input class="form-control" *ngSwitchCase="'textbox'" [formControlName]="question.key" [id]="question.key"
        [type]="question.type">
      <textarea class="text-area-width" style="min-width: 100%;min-height: 100px" *ngSwitchCase="'textarea'"
        [formControlName]="question.key" [id]="question.key"></textarea>
      <input *ngSwitchCase="'checkbox'" [formControlName]="question.key" [id]="question.key" [checked]="question.value"
        type="checkbox" />
        <div *ngSwitchCase="'multicheckbox'" class="form-check-container form-control">
          <div class="form-check d-flex align-items-center mb-sm" 
               *ngFor="let check of question.multipleValues; let i = index">
            <input class="form-check-input mt0" 
                   type="checkbox"
                   [id]="question.key + '_' + i"
                   [value]="check"
                   [checked]="isChecked(question.value, check)"
                   (change)="onCheckboxChange($event, question.key, check)"/>
            <label class="form-check-label ml mb0" [for]="question.key + '_' + i">
              {{check}}
            </label>
          </div>
        </div>
      <select class="form-control text-area-width" style="max-width: 70dvw;" [id]="question.key" *ngSwitchCase="'dropdown'"
        [formControlName]="question.key">
        <option *ngFor="let opt of question.options" [value]="opt.key" [selected]="opt.key==question.value">
          {{opt.value}}</option>
      </select>
      <div *ngSwitchCase="'multiradio'" class="form-check-container form-control">
        <div class="form-check d-flex align-items-center mb-sm" 
             *ngFor="let check of question.multipleValues; let i = index">
          <input class="form-check-input mt0" 
                 type="radio"
                 [formControlName]="question.key"
                 [value]="check"
                 [id]="question.key + '_' + i"/>
          <label class="form-check-label ml mb0" [for]="question.key + '_' + i">
            {{check}}
          </label>
        </div>
      </div>
    </div>
    <!--<div class="text-danger" *ngIf="!isValid"><i class="fa fa-asterisk" aria-hidden="true"></i></div>-->
  </div>
</fieldset>