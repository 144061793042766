import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { ToasterLibraryService } from "./core/toaster-library.service"

@Injectable()
export class HelpersService {
  constructor(private toaster: ToasterLibraryService) {}

  public csvDownload(reqData: any, csvName: string = "") {
    if (reqData.data.length === 0) {
      this.toaster.error(
        "CSV Download Failed",
        "No data available to download."
      )
      return
    }

    let csvContent = "data:text/csv;charset=utf-8,"
    const extName = ".csv"
    let name =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 15) + extName
    if (csvName != "") {
      name = csvName + extName
    }
    if (reqData.filename && reqData.filename != null) {
      name = reqData.filename
    }

    csvContent += reqData.data
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", name)
    document.body.appendChild(link)
    link.click()
  }

  public appendQueryString(url, queryVars) {
    const firstSeperator = url.indexOf("?") == -1 ? "?" : "&"
    const queryStringParts = new Array()
    if (queryVars) {
      for (const key in queryVars) {
        if (queryVars.hasOwnProperty(key)) {
          queryStringParts.push(key + "=" + queryVars[key])
        }
      }
    }

    const queryString = queryStringParts.join("&")
    return url + firstSeperator + queryString
  }

  public genRange(N, seq) {
    return Array.from(new Array(N), (val, index) => seq(index))
  }

  public chartCSVDownload(chartData: any, csvName: string = "") {
    if (!chartData) {
      this.toaster.error(
        "CSV Download Failed",
        "No chart data available to download."
      )
      return
    }

    let csvContent = "data:text/csv;charset=utf-8,"
    const extName = ".csv"
    let name =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 15) + extName
    if (csvName !== "") {
      name = csvName + extName
    }
    if (chartData.filename && chartData.filename != null) {
      name = chartData.filename
    }

    // Check if the data is an array of pairs (e.g., [ ["Marketing - TOF", 29], ... ])
    if (
      Array.isArray(chartData.data) &&
      chartData.data.length > 0 &&
      Array.isArray(chartData.data[0]) &&
      chartData.data[0].length === 2
    ) {
      // Optionally add a header row (e.g., Category,Value)
      csvContent += "Category,Value\r\n"
      chartData.data.forEach((pair: any) => {
        csvContent += `${pair[0]},${pair[1]}\r\n`
      })
    } else if (chartData.labels && chartData.datasets) {
      // Handle the format with separate labels and datasets
      const headerRow = ["Label"]
      chartData.datasets.forEach((dataset: any, index: number) => {
        headerRow.push(dataset.label || `Dataset ${index + 1}`)
      })
      csvContent += headerRow.join(",") + "\r\n"

      chartData.labels.forEach((label: string, index: number) => {
        const row = [label]
        chartData.datasets.forEach((dataset: any) => {
          row.push(
            Array.isArray(dataset.data) && dataset.data.length > index
              ? dataset.data[index]
              : ""
          )
        })
        csvContent += row.join(",") + "\r\n"
      })
    } else {
      this.toaster.error(
        "CSV Download Failed",
        "No chart data available to download."
      )
      return
    }

    // Create a temporary link to trigger the download
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
