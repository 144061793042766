import { ErrorHandler, Injectable } from "@angular/core"
import { captureException } from "@sentry/angular-ivy"
import { addBreadcrumb, setTag } from "@sentry/browser"
import { environment } from "@env/environment"
import { Router } from "@angular/router"

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private router: Router) {}

  handleError(error: any): void {
    if (environment.production) {
      const orgId = localStorage.getItem('account_id')  || 'not-found'
      const userId = localStorage.getItem('User')  || 'not-found'

      addBreadcrumb({
        category: "error",
        message: error.message || "Unknown error",
        level: "error",
        data: {
          url: this.router.url,
          orgId: orgId || 'not-found',
          userId: userId || 'not-found'
        },
      })

      setTag('organization_id', orgId)
      setTag('user_id', userId)

      captureException(error)
    } else {
      // In development, log the error to the console
      console.error("Captured by SentryErrorHandler:", error)
    }
  }
}