import { Component, Input, ElementRef, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({    
    selector: 'add-days-schedule',
    templateUrl: './add-days-schedule.component.html',
    styleUrls: ['./add-days-schedule.component.scss'],
})

export class AddDaysScheduleComponent implements OnInit {
    @Input() model: any;
    @Input() showPriceField: boolean = false;
    @Input() showRoutingNumberField: boolean = true;

    public daysArray: Array<any> = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    public activationStatus: Array<any> = [];
    public endTimeSlotArray: Array<any> = [];
    public startTimeSlotArray: Array<any> = [];
    public activeSchedules: Array<any> = [];
    public currencyCode: string = 'USD';

    constructor(private elementRef: ElementRef, fb: FormBuilder) {
    }
    
     ngOnInit() {
       this.model?.segments.forEach((segment, key) => {
           if(this.daysArray[segment.day]) {
                this.activationStatus[this.daysArray[segment.day]] = true;
                this.activeSchedules[segment.day] = segment.day;
           } else {
               this.activationStatus[this.daysArray[segment.day]] = false;
           }
       });
       this.currencyCode = JSON.parse(localStorage.getItem("account"))?.currency_code;
       this.startTimeSlot();
       this.endTimeSlot();  
    }

    startTimeSlot() {
        let startTime = moment().startOf('day');
        let endTime = moment().endOf('day');
        while (startTime < endTime) {
            this.startTimeSlotArray.push(moment(startTime).format("hh:mm A"));
            startTime.add(30, 'm');
        }
    }

    endTimeSlot() {
        let startTime = moment().startOf('day').add(29, 'm');
        let endTime = moment().endOf('day');
        while (startTime < endTime) {
            this.endTimeSlotArray.push(moment(startTime).format("hh:mm A"));
            startTime.add(30, 'm');
        }
    }

    changeActivationStatus(dayIndex: string, day: string) {
        this.activationStatus[day] = !this.activationStatus[day];
        if(this.activationStatus[day]) {
            let tempObj: any = {
                "day" : dayIndex,
                "start_time" : "12:00 AM",
                "end_time" : "12:29 AM"
            }
            if(this.showPriceField) {
               tempObj.price = '';
            } 
            if(this.showRoutingNumberField) {
                tempObj.routing_number = '';
            }
            this.model?.segments.push(tempObj);
            
        } else {
            _.remove(this.model?.segments,(n: any)=> {
                return n.day == dayIndex;
            });
        }
    }

    addDaySchedule(day: string) {
        let tempObj: any = {
            "day" : day,
            "start_time" : "12:00 AM",
            "end_time" : "12:29 AM"
        }
        if(this.showPriceField) {
            tempObj.price = '';
        } 
        if(this.showRoutingNumberField) {
            tempObj.routing_number = '';
        }
        this.model?.segments.push(tempObj);
    }

    removeDaySchedule(item: number) {
        this.model?.segments.splice(item, 1);
    }

    copyDown(copyDownIndex: number, selectedDayIndex: string) {
        this.model?.segments.forEach((segment, key) => {
           if((segment.day == selectedDayIndex && key > copyDownIndex) || (segment.day > selectedDayIndex)) {
                this.model.segments[key].start_time = this.model?.segments[copyDownIndex].start_time;
                this.model.segments[key].end_time = this.model?.segments[copyDownIndex].end_time;
                this.model.segments[key].routing_number = this.model?.segments[copyDownIndex].routing_number;
                if(this.showPriceField) {
                    this.model.segments[key].price = this.model?.segments[copyDownIndex].price;
                }
           }
       }); 
    }
}