import { Component, Input } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { FormFieldBase } from "../form-field/form-field-base"
@Component({
  selector: "app-lead-df-field",
  templateUrl: "lead-df-field.component.html",
  styleUrls: ["lead-df-field.component.scss"],
})
export class LeadDfFieldComponent {
  @Input() question: FormFieldBase<any>
  @Input() form: FormGroup
  constructor() {}
  get isValid() {
    return this.form.controls[this.question.key].valid
  }

  isChecked(values: string | string[], checkValue: string): boolean {
    if (!values) return false

    // Only handle array logic for multicheckbox
    if (this.question.controlType === "multicheckbox") {
      if (typeof values === "string") {
        const valueArray = values
          .split(/,|\n/)
          .map(v => v.trim())
          .filter(v => v !== "")
        return valueArray.includes(checkValue)
      }

      if (Array.isArray(values)) {
        return values.includes(checkValue)
      }
    }

    // Original logic for other types
    return values === checkValue
  }

  onCheckboxChange(event: any, key: string, value: string) {
    const formControl = this.form.get(key)
    let currentValue = formControl.value || []

    // Convert to array if it's a string
    if (typeof currentValue === "string") {
      currentValue = currentValue.split(",").filter(v => v)
    }

    if (event.target.checked) {
      if (!currentValue.includes(value)) {
        currentValue.push(value)
      }
    } else {
      const index = currentValue.indexOf(value)
      if (index > -1) {
        currentValue.splice(index, 1)
      }
    }

    formControl.setValue(currentValue)
    console.log(`Checkbox ${key} changed:`, currentValue)
  }
}
