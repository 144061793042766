import { FormFieldBase } from "./form-field-base"

export class MultiCheckboxField extends FormFieldBase<string[]> {
  override controlType = "multicheckbox"
  override multipleValues: string[]

  constructor(options: {} = {}) {
    super(options)
    this.multipleValues = options["multipleValues"] || []

    // Handle the value properly
    if (options["value"]) {
      if (Array.isArray(options["value"])) {
        this.value = options["value"]
      } else if (typeof options["value"] === "string") {
        this.value = options["value"]
          .split(/,|\n/)
          .map(v => v.trim())
          .filter(v => v !== "")
      } else {
        this.value = []
      }
    } else {
      this.value = []
    }
  }
}
