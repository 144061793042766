const Leads = {
  text: 'Leads',
  link: '/leads',
  icon: 'icon-layers',
  privilege: 'contacts:can_get_contacts',
  order: 1,
};

const Calls = {
  text: 'Calls',
  link: '/calls',
  icon: 'icon-phone',
  privilege: 'calls:can_get_calls',
  order: 3,
};



const Automations = {
  text: 'Automations',
  link: '/automations',
  icon: 'icon-wrench',
  privilege: 'automations:can_get_automations',
  order: 5,
};
const Email = {
  text: 'Inbox',
  link: '/email',
  icon: 'icon-envelope',
  privilege: 'email:can_get_email',
  order: 2,
};

const Clients = {
  text: 'Clients',
  link: '/clients',
  icon: 'icon-people',
  privilege: 'accounts:can_get_accounts',
  order: 6,
};

const Tasks = {
  text: 'Tasks',
  link: '/tasks',
  icon: 'icon-pencil',
  privilege: 'followups:can_get_followups',
  order: 4,
};

const Dashboard = {
  text: 'Company Snapshot',
  link: '/dashboard',
  icon: 'icon-home',
  privilege: 'contacts:can_get_contacts', //@TODO: add an appropriate permission in the PHP
  order: 7,
};


const Settings = {
  text: 'Settings',
  link: '/settings',
  icon: 'icon-settings',
  submenu: [
    {
      text: 'Main',
      link: '/settings/general',
    },
    {
      text: 'Fields',
      link: '/fields',
    },
    {
      text: 'Forms',
      link: '/forms',
    },
    {
      text: 'Email Templates',
      link: '/email-templates',
    },
    {
      text: 'Leads Prices',
      link: '/leads/leadsprices',
    },
    {
      text: 'WebHooks',
      link: '/web-hooks',
    },
    {
      text: 'Private Labels',
      link: '/private-labels',
    },
    {
      text: 'Integration',
      link: '/integration',
    },
    {
      text: 'Campaigns',
      link: '/campaigns',
    },
  ],
};

const Logout = {
  text: 'Logout',
  link: '/logout',
  icon: 'icon-logout',
};

export default [Dashboard, Leads, Clients, Tasks, Calls, Automations, Email];
