import { FormFieldBase } from "./form-field-base"

export class MultiRadioButtonField extends FormFieldBase<string> {
  override controlType = "multiradio"
  override multipleValues: string[]

  constructor(options: {} = {}) {
    super(options)
    this.multipleValues = options["multipleValues"] || []
    this.value = options["value"] || ""
  }
}