import { Injectable } from "@angular/core"
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http"
import { environment } from "@env/environment"
import {
  MonoTypeOperatorFunction,
  Observable,
  throwError,
  TimeoutError,
  timer,
} from "rxjs"
import { catchError, timeout, map, retryWhen, mergeMap } from "rxjs/operators"
import * as _ from "lodash"

export enum ErrorType {
  Network = "NETWORK_ERROR",
  Timeout = "TIMEOUT_ERROR",
  NotFound = "404_NOT_FOUND",
  Server = "SERVER_ERROR",
  Unauthorized = "UNAUTHORIZED",
  BadRequest = "BAD_REQUEST",
  Unknown = "UNKNOWN_ERROR",
  Conflict = "CONFLICT",
}

export interface ApiError {
  type: ErrorType
  message: string
  statusCode?: number
  timestamp: Date
  path?: string
  error?: any
}

export interface IRequestOptions {
  headers?: HttpHeaders
  observe?: "body"
  params?: HttpParams
  reportProgress?: boolean
  responseType?: "json"
  withCredentials?: boolean
  body?: any
}

@Injectable()
export class RequestLibraryService extends HttpClient {
  private baseUrl = environment.api_base_url
  private readonly TIMEOUT_DURATION = 100000 // 100 seconds
  private readonly MAX_RETRIES = 0

  private createApiErrorPayload(
    error: HttpErrorResponse,
    type: ErrorType,
    defaultMessage: string,
    additionalProps: Partial<ApiError> = {}
  ): ApiError {
    const statusCode = (error as HttpErrorResponse)?.status
    const errorMessage =
      (error as HttpErrorResponse)?.error?.error?.message ||
      (error as HttpErrorResponse)?.error?.message ||
      error.message ||
      defaultMessage

    return {
      type,
      message: errorMessage,
      statusCode: statusCode,
      timestamp: new Date(),
      ...additionalProps,
      error: {
        message: errorMessage,
        ...(error as HttpErrorResponse)?.error,
      },
    }
  }

  private handleApiError(
    error: HttpErrorResponse,
    endpoint: string
  ): Observable<never> {
    let apiError: ApiError

    if (error?.error instanceof ErrorEvent) {
      apiError = this.createApiErrorPayload(
        error,
        ErrorType.Network,
        "Network error occurred. Please check your internet connection."
      )
    } else {
      switch (error?.status) {
        case 0:
          apiError = this.createApiErrorPayload(
            error,
            ErrorType.Network,
            "Unable to connect to the server. Please check your internet connection."
          )
          break

        case 400:
          apiError = this.createApiErrorPayload(
            error,
            ErrorType.BadRequest,
            "An Unexpected Error Occurred!"
          )
          break

        case 401:
          apiError = this.createApiErrorPayload(
            error,
            ErrorType.Unauthorized,
            "Unauthorized access!"
          )
          this.handleUnauthorized()
          break

        case 404:
          apiError = this.createApiErrorPayload(
            error,
            ErrorType.NotFound,
            "Resource not found",
            { path: endpoint }
          )
          break

        case 500:
          apiError = this.createApiErrorPayload(
            error,
            ErrorType.Server,
            "Internal server error"
          )
          break

        case 504:
          apiError = this.createApiErrorPayload(
            error,
            ErrorType.Timeout,
            "Server gateway timeout"
          )
          break

        default:
          apiError = this.createApiErrorPayload(
            error,
            ErrorType.Unknown,
            "An Unknown Error Occurred!"
          )
      }
    }
    console.log(apiError)

    return throwError({
      error: apiError,
    })
  }

  private handleTimeout(): Observable<never> {
    const apiError: ApiError = {
      type: ErrorType.Timeout,
      message: "Request timed out. Please try again.",
      timestamp: new Date(),
      error: {
        message: "Request timed out. Please try again.",
      },
    }
    return throwError({
      error: apiError,
    })
  }

  private handleUnauthorized(): void {
    // Implement your unauthorized logic here
    // For example: redirect to login, clear tokens, etc.
    console.log("Handling unauthorized access")
  }

  public GetAll<T>(endPoint: string, qArgs?: {}): Observable<T> {
    const options: IRequestOptions = {}
    if (qArgs) {
      options.params = this.toHttpParams(qArgs)
    }

    return super.get<T>(this.prepUrl(endPoint), options).pipe(
      timeout(this.TIMEOUT_DURATION),
      this.retryWithDelay(),
      catchError(error => {
        if (error instanceof TimeoutError) {
          return this.handleTimeout()
        }
        const errorResponseData = this.handleApiError(error, endPoint)
        console.log(errorResponseData)
        return errorResponseData
      })
    )
  }

  public BaseGet<T>(
    endPoint: string,
    options?: IRequestOptions
  ): Observable<T> {
    return super.get<T>(endPoint, options)
  }

  public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return super.get<T>(this.prepUrl(endPoint), options).pipe(
      timeout(this.TIMEOUT_DURATION),
      this.retryWithDelay(),
      catchError(error => {
        if (error?.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleApiError(error, endPoint)
      })
    )
  }

  public Post<T>(
    endPoint: string,
    params: Object,
    options?: IRequestOptions
  ): Observable<T> {
    return super.post<T>(this.prepUrl(endPoint), params, options).pipe(
      timeout(this.TIMEOUT_DURATION),
      this.retryWithDelay(),
      catchError(error => {
        if (error?.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleApiError(error, endPoint)
      })
    )
  }

  public Put<T>(
    endPoint: string,
    params: Object,
    options?: IRequestOptions
  ): Observable<T> {
    return super.put<T>(this.prepUrl(endPoint), params, options).pipe(
      timeout(this.TIMEOUT_DURATION),
      this.retryWithDelay(),
      catchError(error => {
        if (error?.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleApiError(error, endPoint)
      })
    )
  }

  public Delete<T>(endPoint: string): Observable<boolean> {
    return super
      .delete<T>(this.prepUrl(endPoint), { observe: "response" })
      .pipe(
        timeout(this.TIMEOUT_DURATION),
        this.retryWithDelay(),
        map(response => response.status === 204),
        catchError(error => {
          if (error?.name === "TimeoutError") {
            return this.handleTimeout()
          }

          if (error?.status === 409) {
            return throwError({
              error: this.createApiErrorPayload(
                error,
                ErrorType.Conflict,
                "Resource cannot be deleted due to conflict",
                { path: endPoint }
              ),
            })
          }

          if (error?.status === 404) {
            return throwError({
              error: this.createApiErrorPayload(
                error,
                ErrorType.NotFound,
                "Resource already deleted or not found",
                { path: endPoint }
              ),
            })
          }

          return this.handleApiError(error, endPoint)
        })
      )
  }

  public GetCSV(endPoint: string, qArgs?: {}) {
    const options: any = {}
    if (qArgs) {
      options.params = this.toHttpParams(qArgs)
      options.responseType = "text"
      options.observe = "response"
    }

    return super.get(this.prepUrl(endPoint), options).pipe(
      timeout(this.TIMEOUT_DURATION),
      map(this.toCSVResponse),
      catchError(error => {
        if (error?.name === "TimeoutError") {
          return this.handleTimeout()
        }
        return this.handleApiError(error, endPoint)
      })
    )
  }

  private prepUrl(url: string) {
    return `${this.baseUrl}${url}`
  }

  private tojsonURI(json) {
    return JSON.stringify(json)
  }

  private toBooleanResponse(res: any) {
    return res.status === 204
  }

  private toCSVResponse(res: any) {
    let fileName = null
    const headers = res.headers
    const contentDisposition = headers.get("content-disposition") ?? ""

    if (contentDisposition) {
      const result =
        contentDisposition.split(";")[1]?.trim().split("=")[1] ?? ""
      fileName = result.replace(/"/g, "")
    }

    if ([200, 201, 202].includes(res.status)) {
      const body = (<any>res).body
      return { data: body, filename: fileName }
    }

    return []
  }

  private toHttpParams(params) {
    return Object.getOwnPropertyNames(params).reduce(
      (p, key) => p.set(key, this.tojsonURI(params[key])),
      new HttpParams()
    )
  }

  private retryWithDelay<T>(): MonoTypeOperatorFunction<T> {
    return retryWhen(errors =>
      errors.pipe(
        mergeMap((error, index) => {
          if (index < this.MAX_RETRIES) {
            return timer(1000)
          }
          return throwError({ error: error })
        })
      )
    )
  }
}
