let environmentProduction = {
  production: true,
  env_key: "production",
  auth_redirect_url: location.protocol + "//" + location.host + "/callback",
  auth_linkedRedirect_url:
    location.protocol + "//" + location.host + "/users/callback",
  auth_login_url: location.protocol + "//" + location.host + "/login",
  forgot_password_url:
    location.protocol + "//" + location.host + "/forgot-password",
  auth_lock_key: "albgzlMl8dBzMtBfQCfvaLs0wyxKmPOk", //Auth0 Lock Key
  auth_domain: "marketingoptimizerprod.us.auth0.com",
  export_leads_url: "https://api.marketingoptimizer.com/api/v1/export/", //Download export leads URL
  import_leads_url: "https://api.marketingoptimizer.com/api/v1/contacts/import", //Import leads URL
  api_base_url: "https://api.marketingoptimizer.com/api/v1", //API Base URL

  stripe_api_key: "pk_live_zWN8d0GY3I00ElqyFYSJcyec",
  intercom_id: "oeuwkbw1",
  sentryEnabled: true,
  sentry: {
    dsn: "https://54485f52325caff79d8b968501637380@o4508325666029568.ingest.us.sentry.io/4508330779213824",
    tracePropagationTargets: [
      /^https:\/\/api\.marketingoptimizer\.com\/api\/v1/,
      /^https:\/\/app\.marketingoptimizer\.com/,
    ],
    // tracesSampleRate: 0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    trackConsole: false,
    trackUserInteractions: true,
    profilesSampleRate: 0.1,
    enableDebug: false,
    sensitiveDataPatterns: [/token=/, /password=/, /auth=/],
  },
  ga_measurement_id: "G-K0LR561MJK",
}

if (location.hostname.indexOf("figdaconsulting") !== -1) {
  environmentProduction.auth_lock_key = "4i0UXD3JiKRi1tZgv7CoQy8pNXLX8Fvh"
  environmentProduction.auth_domain = "figdaconsultingprod.us.auth0.com"
  console.log("figdaconsulting", location.hostname)
}
if (location.hostname.indexOf("progrowth") !== -1) {
  environmentProduction.auth_lock_key = "CIRTH0m94SdV5QaJEJSnK3OT20ePmUmD"
  environmentProduction.auth_domain = "progrowthprod.us.auth0.com"
  console.log("progrowth", location.hostname)
}
if (location.hostname.indexOf("activemarketing") !== -1) {
  environmentProduction.auth_lock_key = "pQgwCJqP1JW0bM2KyKTmYlueTOXLMGvd"
  environmentProduction.auth_domain = "activemarketingprod.us.auth0.com"
  console.log("activemarketing", location.hostname)
}

export const environment = environmentProduction

// export const environment = {
//   production: true,
//   auth_redirect_url: location.protocol + '//' + location.host + '/callback',
//   auth_login_url: location.protocol + '//' + location.host + '/login',
//   forgot_password_url: location.protocol + '//' + location.host + '/forgot-password',
//   auth_lock_key: 'ZVUulAlKHGFjq1GbzeNV8LC3H8zpR9t8', //Auth0 Lock Key
//   export_leads_url: 'https://api.marketingoptimizer.com/api/v1/export/', //Download export leads URL
//   import_leads_url: 'https://api.marketingoptimizer.com/api/v1/contacts/import', //Import leads URL
//   api_base_url: 'https://api.marketingoptimizer.com/api/v1', //API Base URL
//   theme_logo: '//api.marketingoptimizer.com/images/marketingoptimizer.com_logo_450x150.png',
//   stripe_api_key: 'pk_live_zWN8d0GY3I00ElqyFYSJcyec'
// };
