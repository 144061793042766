import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AbTestService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }
    getPaginatedTests(page, page_size, sort, sort_dir, filters) {
        return this.requestLibrary.GetAll('/tests', {
            'options': {
                page: page,
                page_size: page_size,
                sort: sort,
                sort_dir: sort_dir,
                filters: filters
            }
        });
    }
    getExportTests(page_size, sort, sort_dir) {
        return this.requestLibrary.GetCSV('/tests', {
            'options': {
                page_size: page_size,
                sort: sort,
                sort_dir: sort_dir,
                is_export: true
            }
        });
    }

    addEditTest(model) {
        return this.requestLibrary.Post('/tests', model);
    }

    deleteTest(id) {
        return this.requestLibrary.Delete('/tests/' + id);
    }

    getTest(id) {
        return this.requestLibrary.Get('/tests/' + id);
    }

    getTestExperiment() {
        return this.requestLibrary.Get('/tests/experiments');
    }
}
