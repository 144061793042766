import { environment } from './../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!

import { RoutesModule } from './moRoutes/routes.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { LatestVersionModule } from './latest-version/latest-version.module';
import { LayoutModule } from './layout/layout.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BrowserModule } from '@angular/platform-browser';
import { IntercomModule } from 'ng-intercom';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
    return localStorage.getItem("id_token");
}

export const APPLICATION_MODULES = [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    CoreModule,
    LayoutModule,
    SharedModule,
    RoutesModule,
    HttpClientModule,
    JwtModule.forRoot({
        config: {
            tokenGetter: tokenGetter
        },
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    LatestVersionModule,
    NgxPermissionsModule.forRoot(),
    IntercomModule.forRoot({
        appId: environment.production ? environment.intercom_id : "", // from your Intercom config
        updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.production ? environment.ga_measurement_id : ""),
    NgxGoogleAnalyticsRouterModule,
    NgxMaskModule.forRoot(maskConfig),
]
