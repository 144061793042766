import { Component, ElementRef, Input, OnInit, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@service/global.funcs';
import { CampaignService, SettingsService } from '@service/index';
import * as _ from 'lodash';

@Component({
    selector: 'app-show-campaign-list',
    templateUrl: './show-campaign-list.component.html',
})

export class ShowCampaignListComponent implements OnInit, OnChanges {
    @Input() model: any;
    @Input() campaignFor: string = 'valueObj';
    @Input() withSwitchFilter: string = '';
    @Input() view: string;

    public campaignsArray: Array<any> = [];
    public campaignsLoaded: Boolean = false;
    public toggleCampaignLabel = 'Select All';
    public chunkCampaignsArray: {
        chunkSize: number,
        chunkData: Array<any>
    } = { chunkSize: 0, chunkData: [] };

    constructor(
        private elementRef: ElementRef,
        fb: FormBuilder,
        private settingsService: SettingsService,
        private router: Router,
        private route: ActivatedRoute,
        private campaignService: CampaignService
    ) {
    }

    ngOnInit() {
        this.getCampaigns();
    }

    ngOnChanges(changes) {
        this.view = this.view || 'lg';
    }

    getCampaigns() {
        this.campaignService
            .getCompactCampaigns()
            .subscribe((res: any) => {
                if (res.success == 'true') {
                    this.campaignsArray = _.sortBy(res.data, ['channel', 'name']);
                    this.makeChunks();
                }
                this.campaignsLoaded = true;
            }, err => {
                this.router.navigate(['error']);
            });
    }

    addRemoveCampaign(id) {
        if (this.campaignFor == 'dataObj') {
            if (this.checkCampaign(id)) {
                let index = this.model.data.indexOf(id);
                this.model.data.splice(index, 1);
            } else {
                this.model.data.push(id);
            }
        } else {
            if (this.checkCampaign(id)) {
                delete this.model.value[id];
            } else {
                this.model.value[id] = 'on';
                this.model.names.push(this.getCriterionName(id));
            }
        }

    }

    checkCampaign(id) {
        if (this.campaignFor == 'dataObj') {
            if (this.model.data && this.model.data.length > 0) {
                if (this.model.data.indexOf(id) > -1) {
                    return true;
                }
            }
            return false;
        } else {
            // console.log(this.model.value);
            
            for (var campaign in this.model.value) {
                if (campaign == id) {
                    return true;
                }
            }
            return false;
        }
    }
    toggleAllCampaigns() {
        // Logger().info('in: toggleAllCampaigns');
        const campaignToggleAction = this.toggleCampaignLabel;
        this.toggleCampaignLabel = (this.toggleCampaignLabel === 'Select All') ? 'Deselect All' : 'Select All';
        if (this.campaignsArray && this.campaignsArray.length > 0) {
            // console.log(this.model);
            this.campaignsArray.forEach((value) => {
                const id = value.id;
                
                const campaignsArrayModal = campaignToggleAction == 'Select All' ? !_.has(this.model.value, id) : _.has(this.model.value, id);
                if (campaignsArrayModal) {
                    if (this.campaignFor == 'dataObj') {
                        if (this.checkCampaign(id)) {
                            const index = this.model.data.indexOf(id);
                            this.model.data.splice(index, 1);
                        } else {
                            this.model.data.push(id);
                        }
                    } else {
                        if (this.checkCampaign(id)) {
                            delete this.model.value[id];
                        } else {
                            this.model.value[id] = 'on';
                        }
                    }
                }
            });
        }
    }

    getCriterionName(id: number) {
        let criterion_name = '';
        const found = _.find(this.campaignsArray, { id: id });

        if (found != undefined) {
            criterion_name += found['name'];
        }

        return criterion_name;
    }

    makeChunks() {
        // console.log(this.view);
        let chunk = 0;
        let columnSize = 0;

        if (this.view === 'sm') {
            chunk = 12;
            columnSize = 12;
        }
        if (this.view === 'md') {
            chunk = 2;
            columnSize = 2;
        }
        if (this.view === 'lg') {
            chunk = 4;
            columnSize = 3;
        }

        this.chunkCampaignsArray = { chunkSize: columnSize, chunkData: _.chunk(this.campaignsArray, chunk) };
    }
}
