import { Injectable } from "@angular/core"
import { init as sentryInit } from "@sentry/angular-ivy"
import {
  browserTracingIntegration,
  globalHandlersIntegration,
  breadcrumbsIntegration,
  httpContextIntegration,
  linkedErrorsIntegration,
  replayIntegration,
  browserProfilingIntegration,
} from "@sentry/browser"
import { environment } from "@env/environment"
import { Router } from "@angular/router"

@Injectable({ providedIn: "root" })
export class SentryService {
  private static initialized = false

  constructor(private router: Router) {}

  initializeSentry(): void {
    if (!environment.sentryEnabled || SentryService.initialized) return
    if (!environment.production) return

    // For Development Testing
    // console.log("Initializing Sentry...")
    // console.log("Sentry DSN:", environment.sentry.dsn)
    // console.log("Tracing Sample Rate:", environment.sentry.tracesSampleRate)

    sentryInit({
      dsn: environment.sentry.dsn,
      environment: this.getEnvironment(),
      integrations: this.getIntegrations(),
      // tracesSampleRate: environment.sentry.tracesSampleRate,
      replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
      profilesSampleRate: environment.sentry.profilesSampleRate,
      debug: environment.sentry.enableDebug,
      beforeSend: this.sanitizeEvent,
      beforeBreadcrumb: this.filterBreadcrumb,
    })

    SentryService.initialized = true
    // console.log("Sentry initialized.")
  }

  private getEnvironment(): string {
    return environment.env_key === "local"
      ? "development"
      : environment.env_key === "production"
        ? "production"
        : "staging"
  }

  private getIntegrations(): any[] {
    return [
      // browserTracingIntegration({
      //   tracingOrigins: environment.sentry.tracePropagationTargets,
      //   beforeStartSpan: context => {
      //     return {
      //       ...context,
      //       name: this.router.url
      //         .replace(/\/[a-f0-9]{32}/g, "/<hash>")
      //         .replace(/\/\d+/g, "/<digits>"),
      //     }
      //   },
      // }),
      replayIntegration({ maskAllText: false, blockAllMedia: false }),
      browserProfilingIntegration(),
      globalHandlersIntegration({ onerror: true, onunhandledrejection: true }),
      breadcrumbsIntegration({
        console: environment.sentry.trackConsole,
        dom: environment.sentry.trackUserInteractions,
        fetch: true,
        history: true,
        xhr: true,
        sentry: true,
      }),
      httpContextIntegration(),
      linkedErrorsIntegration(),
    ]
  }

  private sanitizeEvent(event: any): any {
    if (event.request?.url) {
      environment.sentry.sensitiveDataPatterns.forEach(pattern => {
        event.request.url = event.request.url.replace(pattern, "[REDACTED]")
      })
    }
    return event
  }

  private filterBreadcrumb(breadcrumb: any): any {
    if (breadcrumb.category === "console" && !environment.sentry.trackConsole) {
      return null
    }
    return breadcrumb
  }
}
